import { Crisp } from "crisp-sdk-web";
Crisp.configure('a50d11e0-ca5e-457d-b87e-bb7ea73f8d6e', {
    autoload: false
});

export function initCrisp(userEmail, userName, userId, OrderId){
    Crisp.setTokenId("client_"+userEmail+"_"+userId+"_"+OrderId);
    Crisp.user.setEmail(userEmail);
    Crisp.user.setNickname(userName + ' ('+location.host+')');

    console.log('>> Load crisp')
    Crisp.load();
}
